import axios from 'axios';
import client from '../lib/http-client';

const CancelToken = axios.CancelToken;

const endpoint = '/uploads';

export default {
  /**
	 * File Upload
	 * @param {Object} params Params
	 * @param {File} params.fileUpload File to be uploaded
	 * @param {*} cb
	 * @param {*} errorCb
	 */
  upload(params, cb, errorCb, progressCallback, cancelCallback) {
    const url = `${endpoint}`;
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        if (progressCallback) progressCallback(percentCompleted);
      },
      cancelToken: new CancelToken(((c) => {
        // An executor function receives a cancel function as a parameter
        if (cancelCallback) {
          // eslint-disable-next-line no-param-reassign
          cancelCallback(c);
        }
      })),
    };
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params, config)
      .then(responseHandler)
      .catch(errorHandler);
  },
};
