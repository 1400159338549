import client from '../lib/http-client';

const endpoint = '/teamMembers';

export default {
  // Create
  create(params, cb, errorCb) {
    const url = endpoint;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get
  get(id, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get List
  getList(params, cb, errorCb) {
    const url = endpoint;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url, { params })
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Bulk Delete
  bulkDelete(ids, cb, errorCb) {
    const params = {
      ids: JSON.stringify(ids),
      // is_deleted: 1,
    };
    const url = `${endpoint}/bulk/delete`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.delete(url, { data: params })
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Update
  update(id, params, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    client.put(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Delete
  delete(id, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    client.delete(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Bulk Create
  bulkCreate(params, cb, errorCb) {
    const url = `${endpoint}/bulk/create`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Bulk Update
  bulkUpdate(params, cb, errorCb) {
    const url = `${endpoint}/bulk/update`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.put(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Export
  exportList(format, cb, errorCb) {
    const url = `${endpoint}/export/${format}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },
};
