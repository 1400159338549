<template>
	<vs-sidebar
		click-not-close
		position-right
		parent="body"
		default-index="1"
		color="primary"
		class="add-new-data-sidebar items-no-padding"
		spacer
		v-model="isSidebarActiveLocal"
	>
		<div class="mt-6 flex items-center justify-between px-6">
			<h4>{{ Object.entries(this.data).length === 0 ? $t('Add New') : $t('Update') }} {{ $t('User') }}</h4>
			<feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
		</div>
		<vs-divider class="mb-0"></vs-divider>

		<component
			:is="scrollbarTag"
			class="scroll-area--data-list-add-new"
			:settings="settings"
			:key="$vs.rtl"
		>
			<div class="p-6">
				<!-- Product Image -->
				<template v-if="dataImg">
					<!-- Image Container -->
					<div class="img-container w-64 mx-auto flex items-center justify-center">
						<img :src="dataImg" alt="img" class="responsive" />
					</div>

					<!-- Image upload Buttons -->
					<div class="modify-img flex justify-between mt-8">
						<input
							type="file"
							class="hidden"
							ref="updateImgInput"
							@change="updateCurrImg"
							accept="image/*"
						/>
						<vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Update Image</vs-button>
						<vs-button type="flat" color="#999" @click="dataImg = null">Remove Image</vs-button>
					</div>
				</template>

				<div class="vx-row">
					<!-- <div class="vx-col sm:w-1/2 w-full mb-2">
						<vs-input v-filter-input
							:label-placeholder="$t('Username')"
							v-model="dataUsername"
							class="mt-8 w-full"
							name="item-username"
							v-validate="'required'"
						/>
						<span
							class="text-danger text-sm"
							v-show="errors.has('item-username')"
							>
							{{ errors.first('item-username') }}
						</span>
					</div> -->
					<div class="vx-col w-full mb-2"
					:class="{'sm:w-1/2': !dataId}">
						<div class="mt-8 mb-2">
							<vs-input v-filter-input-email
								v-validate="'required|email|min:3'"
								data-vv-validate-on="blur"
								class="w-full"
								name="email"
								v-model="dataEmail"
								:label-placeholder="$t('Email')"
							/>
							<span class="text-danger text-sm">{{ errors.first('email') }}</span>
						</div>
					</div>
					<div v-if="!dataId" class="vx-col sm:w-1/2 w-full mb-2">
						<vs-input
							type="password"
							class="mt-8 w-full"
							:label-placeholder="$t('Password')"
							name="item-password"
							v-model="dataPassword"
							v-validate="'min:6'"
						/>
						<span
							class="text-danger text-sm"
							v-show="errors.has('item-password')"
						>{{ errors.first('item-password') }}</span>
					</div>
				</div>

				<div class="vx-row">
					<!-- Name -->
					<div class="vx-col sm:w-1/2 w-full mb-2">
						<vs-input v-filter-input
							:label-placeholder="$t('Name')"
							v-model="dataName"
							class="mt-8 w-full"
							name="item-name"
							v-validate="'required'"
						/>
						<span
							class="text-danger text-sm"
							v-show="errors.has('item-name')"
						>{{ errors.first('item-name') }}</span>
					</div>
					<div class="vx-col sm:w-1/2 w-full mb-2">
						<!-- Phone -->
						<vs-input v-filter-input
							:label-placeholder="$t('Phone')"
							v-model="dataPhone"
							class="mt-8 w-full"
							name="item-phone"
							type="number"
						/>
					</div>
				</div>

				<div class="vx-row">
					<div class="vx-col sm:w-1/2 w-full mb-2">
						<!-- Address -->
						<vs-input v-filter-input
							class="mt-8 w-full"
							:label-placeholder="$t('Address')"
							name="item-address"
							v-model="dataAddress"
						/>
					</div>
					<div class="vx-col sm:w-1/2 w-full mb-2">
						<!-- Region -->
						<vs-input v-filter-input
							class="mt-8 w-full"
							:label-placeholder="$t('Region')"
							name="item-region"
							v-model="dataRegion"
						/>
					</div>
				</div>

				<!-- Birth Date -->
				<!-- <vs-input v-filter-input
					type="date"
					class="mt-3 w-full"
					:label="$t('Birth Date')"
					name="item-birthdate"
					v-model="dataBirthDate"/> -->

				<!-- Job Title -->
				<!-- <vs-input v-filter-input
					class="mt-8 w-full"
					:label-placeholder="$t('Job Title')"
					name="item-job"
					v-model="dataJobTitle"
				/> -->

				<!-- ROLE -->
				<vs-select
					v-model="dataRole"
					:label="$t('Role')"
					class="mt-3 w-full"
					name="item-role"
					v-validate="'required'"
					>
					<vs-select-item
						v-for="item in roles"
						:key="item.value"
						:value="item.value"
						:text="item.text"
					/>
				</vs-select>
				<span
					class="text-danger text-sm"
					v-show="errors.has('item-role')"
				>{{ errors.first('item-role') }}</span>

				<!-- Gender -->
				<vs-select
					v-model="dataGender"
					:label="$t('Gender')"
					class="mt-3 w-full"
					name="item-gender">
					<vs-select-item
						key="male"
						value="male"
						:text="$t('Male')"
					/>
					<vs-select-item
						key="female"
						value="female"
						:text="$t('Female')"
					/>
				</vs-select>

				<!-- Upload -->
				<!-- <vs-upload text="Upload Image" class="img-upload" ref="fileUpload" /> -->

				<!-- <div class="upload-img mt-8" v-if="!dataImg">
					<input
						type="file"
						class="hidden"
						ref="uploadImgInput"
						@change="updateCurrImg"
						accept="image/*"
					/>
					<vs-button @click="$refs.uploadImgInput.click()">Upload Image</vs-button>
				</div> -->
			</div>
		</component>

		<div class="flex flex-wrap items-center p-6" slot="footer">
			<vs-button
				class="mr-6"
				:class="{ 'is-loading': isSaving }"
				:disabled="!isFormValid"
				@click="submitData">
				{{ $t('Submit') }}
			</vs-button>
			<vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{ $t('Cancel') }}</vs-button>
		</div>
	</vs-sidebar>
</template>

<script>
/* eslint-disable camelcase */
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import dayjs from 'dayjs';
import { getAxiosErrorMessage, duplicateVar } from '../lib/helper';
import usersApi from '../api/user';

export default {
  name: 'UserSidebar',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      dataId: null,
      dataName: '',
      dataEmail: '',
      dataUsername: '',
      dataPassword: '',
      dataPhone: '',
      dataRole: 'client',
      dataAddress: '',
      dataRegion: '',
      dataBirthDate: '',
      dataJobTitle: '',
      dataGender: 'male',
      dataImg: null,
      isSaving: false,
      roles: [
        { text: 'Participant', value: 'participant' },
        { text: 'Client', value: 'client' },
        { text: 'Super Admin', value: 'super_admin' },
        // { text: 'Moderator', value: 'moderator' },
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        this.setData();
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar');
          this.$validator.reset();
          this.initValues();
        }
      },
    },
    isFormValid() {
      return (
        !this.errors.any()
				&& this.dataEmail
				&& this.dataRole
      );
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    params() {
      const params = {
        id: this.dataId,
        name: this.dataName,
        email: this.dataEmail,
        // username: this.dataUsername,
        mobile_phone: this.dataPhone,
        role: this.dataRole,
        address: this.dataAddress,
        region: this.dataRegion,
        birth_date: this.dataBirthDate,
        job_title: this.dataJobTitle,
        gender: this.dataGender,
      };
      if (this.dataPassword && this.dataPassword !== '') {
        params.password = this.dataPassword;
      }
      return params;
    },
  },
  methods: {
    setData() {
      const {
        id,
        profile,
        role,
        email,
        username,
        mobile_phone,
      } = duplicateVar(this.data);

      const name = profile.name || '';
      const birthDate = profile.birth_date ? dayjs(profile.birth_date).format('YYYY-MM-DD') : null;
      const phone = mobile_phone || '';
      this.dataId = id;
      this.dataUsername = username;
      this.dataEmail = email;
      this.dataRole = role;
      this.dataName = name;
      this.dataPhone = phone;
      this.dataAddress = profile.address;
      this.dataRegion = profile.region;
      this.dataBirthDate = birthDate;
      this.dataJobTitle = profile.job_title;
      this.dataGender = profile.gender;
      this.initValues();
    },
    initValues() {
      if (this.data.id) return;
      this.dataId = null;
      this.dataName = '';
      this.dataEmail = '';
      this.dataUsername = '';
      this.dataPassword = '';
      this.dataPhone = '';
      this.dataRole = 'client';
      this.dataAddress = '';
      this.dataRegion = '';
      this.dataBirthDate = '';
      this.dataJobTitle = '';
      this.dataGender = 'male';
      // this.dataCategory = null;
      // this.dataOrder_status = 'pending';
      // this.dataPrice = 0;
      this.dataImg = null;
    },
    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.isSaving = true;
          const isUpdate = !!this.dataId;
          const params = this.params;
          const callback = (response) => {
            const user = response.data;
            const message = response.message;
            this.$emit('add', user);
            this.$emit('closeSidebar');
            this.initValues();
            this.$vs.notify({
              title: this.$t('User'),
              text: message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success',
            });
            this.isSaving = false;
          };
          const errorCallback = (e) => {
            const message = getAxiosErrorMessage(e);
            this.$vs.notify({
              title: this.$t('User'),
              text: message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            });
            this.isSaving = false;
          };
          if (isUpdate) {
            usersApi.update(this.dataId, params, callback, errorCallback);
          } else {
            usersApi.create(params, callback, errorCallback);
          }

          // const obj = {
          // 	id: this.dataId,
          // 	name: this.dataName,
          // 	img: this.dataImg,
          // 	category: this.dataCategory,
          // 	order_status: this.dataOrder_status,
          // 	price: this.dataPrice,
          // };

          // if (this.dataId !== null && this.dataId >= 0) {
          // 	this.$store
          // 		.dispatch('dataList/updateItem', obj)
          // 		.catch(() => {
          // 		});
          // } else {
          // 	delete obj.id;
          // 	obj.popularity = 0;
          // 	this.$store
          // 		.dispatch('dataList/addItem', obj)
          // 		.catch(() => {
          // 		});
          // }

          // this.$emit('closeSidebar');
          // this.initValues();
        }
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
  },
};
</script>
