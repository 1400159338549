<template>
	<vs-prompt
		:class="{ 'is-loading': isFetching }"
		class="dialog-broadcast"
		:title="printTitlePrompt()"
		@accept="submitDataBroadCast()"
		@close="close()"
		:accept-text="$t('Broadcast')"
		:is-valid="(dataIds.length > 0 && dataTitle != '' || assignee != null && dataTitle != '') || isSelectAll"
		:cancel-text="$t('Cancel')"
		:active.sync="isDialogActiveLocal">
		<div class="con-exemple-prompt broadcast-container">
			<div class="vx-row">
				<!-- Filter Status -->
				<div class="vx-col w-full mb-3">
					<label>{{ $t('Status Filter') }}</label>
					<vs-select
						:placeholder="$t('Status')"
						max-selected="1"
						autocomplete
						width = "100%"
						v-model="selectedFilter"
						>
						<vs-select-item v-for="item in filterStatuses" :key="item.id" :value="item.id"  :text="item.title ? item.title : ''" />
					</vs-select>
				</div>

				<!-- Select ALl -->
				<div class="vx-col flex w-full mb-4 mt-3">
					<vs-checkbox v-model="isSelectAll">{{ $t('Select All Recipients')}}</vs-checkbox>
				</div>

				<!-- Search keyword -->
				<div class="vx-col w-full mb-5">
					<vs-input v-filter-input
						class="w-full"
						name="item-title"
						:label-placeholder="$t('Keyword')"
						v-model="searchKeyword"
						:disabled="isSelectAll"
					/>
				</div>

				<div v-if="dataType === 'participant'" class="vx-col w-full mb-3">
					<!-- <div class="vx-col sm:w-1/4 w-full mt-1">
						<vs-checkbox v-model="selectAllParticipants">{{ $t('All Attendees') }}</vs-checkbox>
					</div> -->
					<!-- Ids participants -->
					<div>
						<label>{{$t('Recipient')}}</label>
						<vs-select
							class="select-ids"
							:placeholder="`${$t('Search Attendees')}...`"
							max-selected="1000"
							multiple
							autocomplete
							width="100%"
							:disabled="isSelectAll"
							v-model="dataIds"
							@change="onRecipientChange()"
							>
							<vs-select-item class="dialog-broadcast" v-for="item in participantsOptions" :key="item.id" :value="item.id"  :text="item.text"/>
						</vs-select>
					</div>
				</div>
				<div v-if="dataType === 'team_member'" class="vx-col w-full mb-3">
					<!-- Ids team member -->
					<div>
						<label>{{$t('To')}}:</label>
						<vs-select
							:placeholder="$t('Search Team Members...')"
							max-selected="1000"
							multiple
							autocomplet
							width="100%"
							v-model="dataIds"
							>
							<vs-select-item v-for="item in teamMembersOptions" :key="item.id" :value="item.id"  :text="item.name"/>
						</vs-select>
					</div>
					<div class="vx-col sm:w-1/4 w-full mt-1">
						<vs-checkbox v-model="selectAllTeamMembers">{{ $t('All Team Members') }}</vs-checkbox>
					</div>
				</div>
				<div v-if="dataType === 'team_member' && assignee != null" class="vx-col w-full mb-3">
					<!-- Id Assignne From task -->
					<div>
						<label>{{$t('To')}}:</label>
						<vs-input v-filter-input
							class="w-full"
							name="item-title"
							v-model="assignee"
							disabled
						/>
					</div>
				</div>
				<div v-if="dataType === 'user'" class="vx-col w-full mb-3">
					<!-- Ids user -->
					<div>
						<label>{{$t('To')}}:</label>
						<vs-select
							:placeholder="$t('Search User...')"
							max-selected="1000"
							multiple
							autocomplete
							width="100%"
							v-model="dataIds"
							>
							<vs-select-item v-for="item in usersOptions" :key="item.id" :value="item.id"  :text="`${item.profile.first_name} ${item.profile.last_name}`"/>
						</vs-select>
					</div>
					<div class="vx-col sm:w-1/4 w-full mt-1">
						<vs-checkbox v-model="selectAllUsers">{{ $t('All Users') }}</vs-checkbox>
					</div>
				</div>

				<!-- Templates -->
				<div class="vx-col w-full mb-3">
					<div class="vx-row">
						<div class="vx-col sm:w-1/2 w-full mt-1">
							<label>{{ $t('Templates') }}</label>
							<vs-select
								:placeholder="$t('Select template')"
								max-selected="1"
								autocomplete
								width = "100%"
								v-model="dataSelectedTemplate"
								@change="onChangeTemplate(dataSelectedTemplate)"
								>
								<vs-select-item v-for="item in broadcastTemplates" :key="item.id" :value="item.id"  :text="item.title ? $t(item.title) : ''" />
							</vs-select>
						</div>
						<div class="vx-col sm:w-1/2 w-full mt-1">
							<label>{{ $t('Previous Broadcast') }}?</label>
							<vs-select
								:placeholder="$t('Select from previous broadcast')"
								max-selected="1"
								autocomplete
								width = "100%"
								v-model="dataPrevious"
								@change="onChangePreviousBroadcast(dataPrevious)"
								>
								<vs-select-item v-for="item in broadcastsOptions" :key="item.id" :value="item.id"  :text="item.title ? item.title : ''" />
							</vs-select>
						</div>
					</div>
				</div>

				<div class="vx-col flex w-full mb-5 mt-3">
					<label class="mr-5">{{ $t('Send via') }}</label>
					<vs-checkbox v-model="dataIsSendEmail">{{ $t('Email')}}</vs-checkbox>
					<vs-checkbox v-model="dataIsSendSms">{{ $t('SMS')}}</vs-checkbox>
				</div>

				<!-- Title -->
				<div class="vx-col w-full mb-5">
					<vs-input v-filter-input
						class="w-full"
						name="item-title"
						:label-placeholder="$t('Title')"
						v-model="dataTitle"
					/>
				</div>

				<!-- Content -->
				<div class="vx-col w-full mb-3">
					<div class="vx-row">
						<div class="vx-col w-full mb-3">
							<div>
								<vs-textarea  v-filter-input
									:label="$t('Message')"
									class="w-full input-message"
									name="item-content"
									:label-placeholder="$t('Message')"
									v-model="dataContent"
								/>
							</div>
							<div class="text-gray">
								{{ $t('Do not edit the text in bracket, it can be deleted but not edited') }}
							</div>
						</div>
					</div>
				</div>

				<!-- File -->
				<div class="vx-col w-full sm:w-1/2 mb-3">
					<!-- Image -->
					<template>
						<!-- Image upload Buttons -->
						<div class="modify-img flex mt-2">
							<input
								type="file"
								class="hidden"
								ref="updateImgInput"
								@change="updateCurrFile"
								accept="all/*"
							/>
							<feather-icon icon='UploadIcon' id="uploadIcon" svgClasses='h-5 w-5' class='mr-3 btn-upload-attachment' @click="$refs.updateImgInput.click()"/>
							<label for="uploadIcon" @click="$refs.updateImgInput.click()">{{ $t('Attachment') }}</label>
						</div>

						<!-- Image Container -->
						<div v-if="dataFile" class="img-container mt-3">
							<video controls v-if="isVideo(dataFile)" width="100px" height="100px" class="card-video-top">
								<source :src="dataFile" type="video/mp4">
							</video>
							<img v-else :src="dataFile" width="150px" height="150px">
							<div class="mt-5">
								<vs-button type="relief" color="danger" size="small" @click="dataFile = null" v-if="dataFile">
									{{ $t('Remove File') }}
								</vs-button>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</vs-prompt>
</template>

<script>
/* eslint-disable camelcase */
import { getAxiosErrorMessage, duplicateVar } from '../lib/helper';
import { BROADCAST_TEMPLATES } from '../lib/statics';
import broadcastsApi from '../api/broadcast';
import participantsApi from '../api/participant';
import teamMembersApi from '../api/team-member';
import usersApi from '../api/user';
import fileApi from '../api/file';


export default {
  name: 'BroadcastDialog',
  props: {
    isDialogActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    eventId: {
      type: Number,
    },
    type: {
      type: String,
    },
    assignee: {
      type: String,
    },
    eventSlug: {
      type: String,
    },
    teamSlug: {
      type: String,
    },
  },
  components: {
  },
  data() {
    return {
      dataId: null,
      dataEventId: null,
      dataAssignee: null,
      dataTitle: '',
      dataContent: '',
      dataStatus: '',
      dataFile: null,
      dataType: null,
      dataIsSendSms: false,
      dataIsSendEmail: true,
      dataIds: [],
      dataPrevious: null,
      dataOrder: '',
      isSaving: false,
      isUploading: false,
      participants: [],
      user: [],
      teamMembers: [],
      broadcasts: [],
      selectAllParticipants: false,
      selectAllTeamMembers: false,
      selectAllUsers: false,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      icon: null,
      types: [
        { text: 'Attendees', value: 'participant' },
        { text: 'User', value: 'user' },
        { text: 'Team Member', value: 'team_member' },
      ],
      filterStatuses: [
        { title: 'Invitation Sent', id: 'invitation_sent' },
        { title: 'Invitation broadcast failed', id: 'failed_sent_broadcast' },
        { title: 'Invitation sent failed', id: 'failed_sent_invitation' },
        { title: 'Logged In', id: 'logged_in' },
        { title: 'All', id: 'all' },
      ],
      selectedFilter: 'all',
      isFetching: false,
      broadcastTemplates: duplicateVar(BROADCAST_TEMPLATES),
      dataSelectedTemplate: null,
      searchKeyword: '',
      isSelectAll: false,
    };
  },
  watch: {
    isDialogActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
        this.fetchBroadcast();
      } else {
        this.setData();
      }
    },
    selectAllParticipants() {
      if (this.selectAllParticipants) this.selectAllParticipantIds();
      else this.removeAllBroadcast();
    },
    selectAllTeamMembers() {
      if (this.selectAllTeamMembers) this.selectAllTeamMemberIds();
      else this.removeAllBroadcast();
    },
    selectAllUsers() {
      if (this.selectAllUsers) this.selectAllUserIds();
      else this.removeAllBroadcast();
    },
    isDialogActiveLocal() {
      if (this.isDialogActiveLocal) this.fetch();
      else {
        this.dataSelectedTemplate = null;
      }
    },
    selectedFilter() {
      this.selectAllUserIds = [];
      this.dataIds = [];
    },
    dataPrevious() {
      if (this.dataPrevious) this.dataSelectedTemplate = null;
    },
    dataSelectedTemplate() {
      if (this.dataSelectedTemplate) this.dataPrevious = null;
    },
  },
  computed: {
    isDialogActiveLocal: {
      get() {
        return this.isDialogActive;
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar');
          this.$validator.reset();
          this.initValues();
          this.dataPrevious = null;
        }
      },
    },
    participantsOptions() {
      const participants = duplicateVar(this.participants);
      const recipients = [];
      for (let index = 0; index < participants.length; index++) {
        const participant = participants[index];
        if (participant.user && participant.user.name) {
          const newItem = {
            id: participant.id,
            text: `${participant.user.name} (${participant.user.email})`,
          };
          if (this.selectedFilter === participant.status) {
            recipients.push(newItem);
          } else if (this.selectedFilter === 'all') {
            recipients.push(newItem);
          }
        }
      }
      const keyword = this.searchKeyword.toLowerCase();
      const filter = (item) => {
        const title = item.text ? item.text.toLowerCase() : '';
        const isTextMatched = title.indexOf(keyword) !== -1;
        const isMatched = isTextMatched;
        return isMatched;
      };
      const filtered = recipients.filter(filter);

      const allItem = {
        id: 'all',
        text: this.$t('All'),
      };
      filtered.unshift(allItem);
      return filtered;
    },
    teamMembersOptions() {
      const teamMembers = duplicateVar(this.teamMembers);
      return teamMembers;
    },
    usersOptions() {
      const users = duplicateVar(this.users);
      return users;
    },
    broadcastsOptions() {
      const broadcasts = duplicateVar(this.broadcasts);
      return broadcasts;
    },
    isFormValid() {
      return (
        !this.errors.any()
      );
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    params() {
      const params = {
        id: this.dataId,
        title: this.dataTitle,
        content: this.dataContent,
        status: this.dataStatus,
        file: this.dataFile,
        is_send_sms: this.dataIsSendSms,
        is_send_email: this.dataIsSendEmail,
        type: this.dataSelectedTemplate,
      };
      if (this.dataEventId) params.event_id = this.dataEventId;
      if (this.dataAssignee) {
        params.ids = this.dataAssignee;
      } else {
        params.ids = JSON.stringify(this.dataIds);
      }
      return params;
    },
  },
  methods: {
    setData() {
      const {
        id,
        event_id,
        title,
        content,
        status,
        file,
        type,
        is_send_sms,
        is_send_email,
        ids,
        assignee,
      } = duplicateVar(this.data);

      this.dataId = id;
      this.dataEventId = event_id;
      this.dataAssignee = assignee;
      this.dataTitle = title;
      this.dataContent = content;
      this.dataStatus = status;
      this.dataFile = file;
      this.dataType = type;
      this.dataIsSendSms = is_send_sms;
      this.dataIsSendEmail = is_send_email;
      this.dataIds = ids;
      this.initValues();
    },
    initValues() {
      if (this.data.id) return;
      this.dataId = null;
      this.dataEventId = this.eventId;
      this.dataAssignee = this.assignee;
      this.dataType = this.type;
    },
    submitDataBroadCast() {
      this.isSaving = true;
      const title = this.$t('Broadcast');
      const isUpdate = !!this.dataId;
      const params = this.params;

      if (this.isSelectAll) params.ids = JSON.stringify([]);

      // params.status = status;
      const callback = (response) => {
        const message = response.message;
        this.$emit('closeSidebar');
        this.initValues();
        this.$vs.notify({
          title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isSaving = false;
        this.dataIds = [];
        this.resetForm();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSaving = false;
      };
      if (isUpdate) {
        broadcastsApi.update(this.dataId, params, callback, errorCallback);
      } else {
        broadcastsApi.create(params, callback, errorCallback);
      }
    },
    printTitlePrompt() {
      return `${this.$t('Broadcast')}`;
    },
    fetchParticipants() {
      this.isFetching = true;
      const params = {
        page: 1,
        limit: 99999,
        event_slug: this.eventSlug,
      };
      const callback = (response) => {
        const participants = response.data;
        this.participants = participants;
        this.isFetching = false;
      };
      const errorCallback = () => {};
      participantsApi.getList(params, callback, errorCallback);
      this.isFetching = false;
    },
    fetchTeamMembers() {
      this.isFetching = true;
      const params = {
        page: 1,
        limit: 99999,
        event_slug: this.eventSlug,
        team_slug: this.teamSlug,
      };
      const callback = (response) => {
        const teamMembers = response.data;
        this.teamMembers = teamMembers;
        this.isFetching = false;
      };
      const errorCallback = () => {
        this.isFetching = false;
      };
      teamMembersApi.getList(params, callback, errorCallback);
    },
    fetchUsers() {
      this.isFetching = true;
      const params = {
        page: 1,
        limit: 99999,
      };
      const callback = (response) => {
        const users = response.data;
        this.users = users;
        this.isFetching = false;
      };
      const errorCallback = () => {
        this.isFetching = false;
      };
      usersApi.getUsers(params, callback, errorCallback);
    },
    updateCurrFile(input) {
      if (input.target.files && input.target.files[0]) {
        this.isUploading = true;
        const file = input.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.dataFile = e.target.result;
        };
        reader.readAsDataURL(file);

        // Upload Image
        const formData = new FormData();
        formData.append('file', file);
        const callback = (response) => {
          const url = response.data;
          this.dataFile = url;
          this.isUploading = false;
        };
        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title: this.$t('Upload Image'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isUploading = false;
        };

        fileApi.upload(formData, callback, errorCallback);
      }
    },
    selectAllParticipantIds() {
      const options = this.participantsOptions.filter(curr => curr.id !== 'all');
      const participantsIds = options.map(({ id }) => id);
      this.dataIds = participantsIds;
    },
    selectAllTeamMemberIds() {
      const teamMemberIds = this.teamMembersOptions.map(({ id }) => id);
      this.dataIds = teamMemberIds;
    },
    selectAllUserIds() {
      const userIds = this.usersOptions.map(({ id }) => id);
      this.dataIds = userIds;
    },
    removeAllBroadcast() {
      this.dataIds = [];
    },
    isVideo(filename) {
      return /\.mp4$/i.test(filename);
    },
    fetchBroadcast() {
      this.isFetching = true;
      this.isFetching = true;
      const params = {
        limit: 25,
        event_id: this.eventId,
        order_by: 'created_at',
        sort_by: 'desc',
      };
      const callback = (response) => {
        const broadcasts = response.data;
        this.broadcasts = broadcasts;
        this.isFetching = false;
      };
      const errorCallback = () => {
        this.isFetching = false;
      };
      broadcastsApi.getList(params, callback, errorCallback);
    },
    onChangePreviousBroadcast(dataPrevious) {
      const index = this.broadcasts.findIndex(({ id }) => id === dataPrevious);
      if (index !== -1) {
        this.dataTitle = this.broadcasts[index].title;
        this.dataContent = this.broadcasts[index].content;
        this.dataFile = this.broadcasts[index].file;
        this.dataIsSendSms = this.broadcasts[index].is_send_sms;
        this.dataIsSendEmail = this.broadcasts[index].is_send_email;
      }
    },
    onChangeTemplate(dataSelectedTemplate) {
      const index = this.broadcastTemplates.findIndex(({ id }) => id === dataSelectedTemplate);
      if (index !== -1) {
        this.dataTitle = this.broadcastTemplates[index].title;
        this.dataContent = this.broadcastTemplates[index].content;
      }
    },
    close() {
      this.isDialogActiveLocal = false;
      this.dataIds = [];
      this.resetForm();
    },
    resetForm() {
      this.dataContent = '';
      this.dataFile = null;
      this.dataTitle = '';
      this.dataIsSendSms = false;
      this.dataIsSendEmail = true;
    },
    fetch() {
      if (this.type === 'user') this.fetchUsers();
      if (this.type === 'member') this.fetchTeamMembers();
      if (this.type === 'participant') this.fetchParticipants();
    },
    onRecipientChange() {
      const isAllIndex = this.dataIds.findIndex(curr => curr === 'all');
      if (isAllIndex !== -1) {
        this.removeAllBroadcast();
        this.selectAllParticipantIds();
      }
    },
  },
  created() {
  },
};
</script>
<style lang="scss">
.dialog-broadcast.con-vs-dialog .vs-dialog .vs-dialog-text {
    padding: 1rem;
	max-height: 530px;
	overflow: auto;
}
.dialog-broadcast.con-vs-dialog .vs-dialog {
	max-width: 600px;
    min-width: 300px;
    width: 45%;
}
.dialog-broadcast {
	z-index: 99999;
}
.data-previous{
	position: absolute;
	right: 0;
}
.broadcast-container{
	position: relative;
}
.btn-upload-attachment {
	padding: 5px;
    background-color: #4A91DF;
    border-radius: 50%;
    color: #fff;
}
.input-message textarea {
	min-height: 150px;
}
</style>
