<template>
	<div class="data-list-container main-list-view mt-6 ml-6">
		<div class="mb-4 mt-6 ml-6" style="font-size: 28px!important">{{$t('Users')}}</div>
		<p class="ml-6 mb-6">{{ $t('Manage all user') }}</p>
		<user-sidebar
			:isSidebarActive="addNewDataSidebar"
			:data="sidebarData"
			@closeSidebar="toggleDataSidebar"
			@add="insert"
		/>

		<!-- Dialog Broadcast -->
		<broadcast-dialog
			:isDialogActive="AddNewDataDialogBroadcast"
			:data="dialogDataBroadcast"
			:type="type"
			@closeSidebar="toggleDataDialogBroadcast"
		/>

		<vs-table
			ref="table"
			multiple
			search
			sst
			:pagination="false"
			:data="users"
			:max-items="limit"
			:total="totalUsers"
			v-model="selected"
			@search="onSearch"
			@sort="onSort"
			@change-page="changePage">
			<div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
				<div class="flex flex-wrap-reverse items-center data-list-btn-container">
					<!-- ADD NEW -->
					<div
						class="btn-add-new px-4 py-25 mb-4 mr-4 vs-button vs-button-success vs-button-filled rounded-lg cursor-pointer"
						@click="addNewData">
						<span class="">{{ $t('Add New') }}</span>
					</div>

					<!-- Bulk -->
						<vs-dropdown
							class="dd-actions cursor-pointer mr-4 mb-4"
							vs-trigger-click>
							<div
								class="px-4 py-25 shadow-drop vs-button vs-button-primary vs-button-filled rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center">
								<span class="mr-3">{{ $t('Bulk Data') }}</span>
								<feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
							</div>
							<vs-dropdown-menu>
								<!-- Export -->
								<vs-dropdown-item>
									<div
										class="flex items-center"
										:class="{ 'is-loading': isExporting }"
										@click="listExport = true">
										<span>{{ $t('Export') }}</span>
									</div>
								</vs-dropdown-item>
								<!-- Import -->
								<vs-dropdown-item @click="dialogImport = true">
									<label
										class="flex items-center cursor-pointer"
										:class="{ 'is-loading': isImporting }">
										<span>{{ $t('Import') }}</span>
										<!-- <input type="file" class="hidden" @change="onImport"> -->
									</label>
								</vs-dropdown-item>
							</vs-dropdown-menu>
						</vs-dropdown>
					<!-- ACTION - DROPDOWN -->
					<vs-dropdown
						class="dd-actions cursor-pointer mr-4 mb-4 ml-auto"
						vs-trigger-click>
						<div
							class="px-4 py-25 shadow-drop vs-button vs-button-light rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center">
							<span class="mr-3">{{ $t('With Selected') }}</span>
							<feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
						</div>

						<vs-dropdown-menu>
								<!-- Delete -->
								<vs-dropdown-item
									:disabled="selected.length === 0">
									<span class="flex items-center" @click="bulkDelete">
										<feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
										<span>{{ $t('Delete') }}</span>
									</span>
								</vs-dropdown-item>
						</vs-dropdown-menu>
					</vs-dropdown>

					<!-- BROADCAST -->
					<div
						class="btn-add-new px-4 py-25 mb-4 mr-4 vs-button vs-button-success vs-button-filled rounded-lg cursor-pointer"
						@click="AddNewBroadcast">
						<span class="">{{ $t('Broadcast') }}</span>
					</div>
				</div>
				<div class="d-flex">
				<!-- Filter by status -->
				<vs-dropdown
					class="dd-actions cursor-pointer mr-3 mb-4"
					vs-trigger-click>
					<!-- <vx-tooltip v-if="isAll" text="All" position="top" color="warning"> -->
					<div
						class="px-4 py-25 shadow-drop vs-button vs-button-primary vs-button-filled rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center">
						<span class="mr-3">{{ $t('Filter by') }}</span>
						<feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
					</div>
					<!-- </vx-tooltip> -->
					<vs-dropdown-menu>
						<!-- All -->
						<vs-dropdown-item @click="filterAll">
							<div>
								<vs-icon v-if="isFilterAll" class="icon-filter" color="#28A3C7">brightness_1</vs-icon>
								<span>{{ $t('All') }}</span>
							</div>
						</vs-dropdown-item>
						<!-- Super admin -->
						<vs-dropdown-item @click="filterSuperAdmin">
							<label
								class="flex items-center cursor-pointer">
								<vs-icon v-if="isFilterSuperAdmin" class="icon-filter" color="#28A3C7">brightness_1</vs-icon>
								<span>{{ $t('Super Admin') }}</span>
							</label>
						</vs-dropdown-item>
						<!-- Client -->
						<vs-dropdown-item @click="filterClient">
							<label
								class="flex items-center cursor-pointer">
								<vs-icon v-if="isFilterClient" class="icon-filter" color="#28A3C7">brightness_1</vs-icon>
								<span>{{ $t('Client') }}</span>
							</label>
						</vs-dropdown-item>
						<!-- Participant -->
						<vs-dropdown-item @click="filterParticipant">
							<label
								class="flex items-center cursor-pointer">
								<vs-icon v-if="isFilterParticipant" class="icon-filter" color="#28A3C7">brightness_1</vs-icon>
								<span>{{ $t('Participant') }}</span>
							</label>
						</vs-dropdown-item>
					</vs-dropdown-menu>
				</vs-dropdown>
				<!-- ITEMS PER PAGE -->
				<vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
					<div
						class="px-4 py-25 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center  font-medium">
						<span class="mr-2">
							{{ page * limit - (limit - 1) }} - {{ users.length > 0 ? page * limit : users.length }} of {{ totalUsers }}
						</span>
						<feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
					</div>
					<!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
					<vs-dropdown-menu>
						<vs-dropdown-item @click="changeLimit(10)"><span>10</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(25)"><span>25</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(50)"><span>50</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(100)"><span>100</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(200)"><span>200</span></vs-dropdown-item>
					</vs-dropdown-menu>
				</vs-dropdown>
				</div>
			</div>

			<template slot="thead">
				<vs-th sort-key="username">{{ $t('Username') }}</vs-th>
				<vs-th sort-key="email">{{ $t('Email') }}</vs-th>
				<vs-th sort-key="name">{{ $t('Name') }}</vs-th>
				<vs-th sort-key="role">{{ $t('Role') }}</vs-th>
				<vs-th>{{ $t('Action') }}</vs-th>
			</template>

			<template slot-scope="{data}">
				<tbody>
					<vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
						<vs-td>
							<p class="user-username font-medium truncate">{{ tr.username }}</p>
						</vs-td>

						<vs-td>
							<!-- <p class="product-category">{{ tr.category | title }}</p> -->
							<p class="user-email">{{ tr.email }}</p>
						</vs-td>

						<!-- <vs-td>
							<vs-progress
								:percent="Number(tr.popularity)"
								:color="getPopularityColor(Number(tr.popularity))"
								class="shadow-md"
							/>
						</vs-td>-->

						<!-- <vs-td>
							<vs-chip
								:color="getOrderStatusColor(tr.order_status)"
								class="product-order-status"
							>{{ tr.order_status | title }}</vs-chip>
						</vs-td>-->

						<vs-td>
							<p class="user-name">{{ tr.profile.name | title }}</p>
						</vs-td>

						<vs-td>
							<p class="user-role">{{ tr.role | title }}</p>
						</vs-td>

						<vs-td class="whitespace-no-wrap">
							<feather-icon
								icon="EditIcon"
								v-tooltip="$t('Edit')"
								svgClasses="w-5 h-5 hover:text-primary stroke-current"
								@click.stop="editData(tr)"
							/>
							<feather-icon
								icon="LockIcon"
								v-tooltip="$t('Change Password')"
								class="ml-2"
								svgClasses="w-5 h-5 hover:text-primary stroke-current"
								@click.stop="changePassword(tr)"
							/>
							<feather-icon
								icon="TrashIcon"
								svgClasses="w-5 h-5 hover:text-danger stroke-current"
								class="ml-2"
								@click.stop="deleteData(tr)"
							/>
						</vs-td>
					</vs-tr>
				</tbody>
			</template>
		</vs-table>
		<!-- export item -->
		<vs-popup
			style="color:rgb(255,255,255);"
			background-color="rgba(255,255,255,.6)"
			:title="$t('Export')"
			:active.sync="listExport">
			<p class="text-base text-black mb-5">
				{{ $t('Please select in what file format you want to export in this data') }}:
			</p>
			<div class="p-2">
				<div
					class="cursor-pointer"
					:class="{ 'is-loading': isExporting }"
					@click="exportUsers('excel')">
					<img src="@/assets/images/file-icons/xls.png" alt="xls" width="20px;"/>
					<span class="ml-2 text-base item-text-export text-black">{{ $t('Excel File') }}</span>
				</div>
			</div>
			<div class="p-2">
				<div
					class="cursor-pointer"
					:class="{ 'is-loading': isExporting }"
					@click="exportUsers('docx')">
					<img src="@/assets/images/file-icons/doc.png" alt="xls" width="20px;"/>
					<span class="ml-2 text-base item-text-export text-black">{{ $t('Document File') }}</span>
				</div>
			</div>
		</vs-popup>
		<!-- import item -->
		<vs-prompt
			class="import"
			:accept-text="$t('Start Import')"
			:is-valid="tmpUsers.length > 0"
			:cancel-text="$t('Cancel')"
			@accept="createBulk"
			@close="closeDialogImport"
			@cancel="closeDialogImport"
			style="color:rgb(255,255,255);"
			background-color="rgba(255,255,255,.6)"
			:title="$t('Import Data')"
			:active.sync="dialogImport">
			<div class="modal-import-box">
				<div>
					<vs-row vs-type="flex" vs-justify="center">
						<vue-xlsx :isShowFileXlsx="dialogImport" @on-select-file="handleImportBulk" @drop.prevent="handleImportBulk">
								{{ $t('Select File') }}
						</vue-xlsx>
					</vs-row>
					<div class="text-base text-grey" mb-5>{{ $t('All data will be imported, similar data will be ignored') }}</div>
				</div>
			</div>
			<div class="title-download-import">
				{{ $t('Download Template For Import') }}
			</div>
			<div class="item-download-import">
				<a href="./../../template-xlsx/Template_Users.csv" download>{{ $t('Download') }} CSV Template - {{ $t('Users') }}</a>
			</div>
			<div class="item-download-import">
				<a href="./../../template-xlsx/Template_Users.xlsx" download>{{ $t('Download') }} Excel Template - {{ $t('Users') }}</a>
			</div>
			<div class="item-download-import">
				{{ $t('View Documentation') }}
			</div>
		</vs-prompt>
		<!-- Pagination -->
		<vs-pagination
			class="mt-3"
			:total="totalPages"
			:max-items="limit"
			:size-array="totalUsers"
			v-model="page">
		</vs-pagination>
		<change-password-sidebar
			:isSidebarActive="addNewDataSidebarPassword"
			:data="sidebarDataPassword"
			@closeSidebar="toggleDataSidebarPassword"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import readXlsxFile from 'read-excel-file';
import VueXlsx from '@/components/excel/ImportExcel.vue';
import UserSidebar from '@/components/UserSidebar.vue';
import ChangePasswordSidebar from '@/components/ChangePasswordSidebar.vue';
import BroadcastDialog from '@/components/BroadcastDialog.vue';
import { USER_SCHEMA as schema } from '@/lib/statics';
import {
  duplicateVar,
  delay,
  getAxiosErrorMessage,
  downloadBlob,
} from '@/lib/helper';
import usersApi from '@/api/user';

export default {
  name: 'Users',
  components: {
    UserSidebar,
    BroadcastDialog,
    VueXlsx,
    ChangePasswordSidebar,
  },
  data() {
    return {
      keyword: '',
      users: [],
      tmpUsers: [],
      isFetching: false,
      isAll: false,
      page: 1,
      limit: 10,
      orderBy: 'created_at',
      sortBy: 'asc',
      totalPages: 0,
      totalUsers: 0,
      selected: [],
      selectedItem: null,
      isFormVisible: false,
      isDeleteVisible: false,
      isDeleting: false,
      isMounted: false,
      addNewDataSidebar: false,
      AddNewDataDialogBroadcast: false,
      sidebarData: {},
      dialogDataBroadcast: {},
      isImporting: false,
      type: 'user',
      listExport: false,
      dialogImport: false,
      isExporting: false,
      // change password
      addNewDataSidebarPassword: false,
      sidebarDataPassword: {},
      // filter role
      isFilterSuperAdmin: false,
      isFilterClient: false,
      isFilterParticipant: false,
      isFilterAll: false,
      role: '',
    };
  },
  methods: {
    fetch(reset) {
      this.$vs.loading();
      this.isFetching = true;
      if (reset) {
        this.page = 1;
        this.users = [];
      }
      const keyword = this.keyword;
      const params = {
        page: this.page,
        limit: this.limit,
        order_by: this.orderBy,
        sort_by: this.sortBy,
      };
      if (this.role) params.role = this.role;
      if (keyword !== '') {
        params.keyword = keyword;
      }
      const callback = (response) => {
        const users = response.data;
        const lastPage = response.lastPage;
        // const allUsers = this.users.concat(users);
        this.users = users;
        this.isAll = users.length === 0 || users.length < this.limit;
        // this.page++;
        if (lastPage) {
          this.totalPages = lastPage;
        }
        this.totalUsers = response.total;
        this.isFetching = false;
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Categories'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isFetching = false;
        this.$vs.loading.close();
      };
      usersApi.getUsers(params, callback, errorCallback);
    },
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    deleteData(item) {
      this.selectedItem = duplicateVar(item);
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: `${this.$t('Are you sure want to delete')} "${item.profile.name}"?`,
        accept: this.deleteRecord,
        acceptText: this.$t('Delete'),
        cancelText: this.$t('Cancel'),
      });
    },
    bulkDelete() {
      const total = this.selected.length;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: `${this.$t('Are you sure want to delete')}  ${total} ${this.$t('Users')}?`,
        accept: this.deleteRecords,
        acceptText: this.$t('Delete'),
        cancelText: this.$t('Cancel'),
      });
    },
    showCustomFields() {
      this.isCustomFieldsVisible = true;
    },
    editData(data) {
      this.selectedItem = duplicateVar(data);
      this.sidebarData = duplicateVar(data);
      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    onSearch(keyword) {
      delay(() => {
        this.keyword = keyword ? keyword.trim() : '';
        this.fetch(true);
      }, 1000);
    },
    onSort(sortKey, sortType) {
      this.orderBy = sortKey;
      this.sortBy = sortType;
      if (this.orderBy && this.sortBy) this.fetch(true);
    },
    deleteRecord() {
      this.$vs.loading();
      const item = this.selectedItem;
      const callback = (response) => {
        this.remove(item.id);
        const message = response.message;
        this.$vs.notify({
          title: this.$t('User'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('User'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.$vs.loading.close();
      };
      usersApi.delete(item.id, callback, errorCallback);
    },
    deleteRecords() {
      const ids = this.selected.map(({ id }) => id);
      const callback = (response) => {
        ids.forEach((id) => {
          this.remove(id);
        });

        const message = response.message;
        this.$vs.notify({
          title: this.$t('User'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('User'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      usersApi.bulkDelete(ids, callback, errorCallback);
    },
    insert(user) {
      const index = this.users.findIndex(({ id }) => id === user.id);
      if (index === -1) {
        this.users.push(user);
        this.totalUsers++;
      } else {
        this.$set(this.users, index, user);
      }
    },
    remove(itemId) {
      const index = this.users.findIndex(({ id }) => id === itemId);
      if (index !== -1) {
        this.users.splice(index, 1);
        this.totalUsers--;
      }
    },
    changeLimit(value) {
      this.limit = value;
      this.fetch(true);
    },
    changePage(page) {
      this.page = page;
    },
    onSelectAll(event) {
      const elem = event.target;
      const isCheckbox = elem.classList.contains('vs-checkbox--input');
      const isCheckAll = !!elem.closest('.vs-table--thead');
      if (isCheckbox && isCheckAll) {
        const isChecked = elem.checked;
        if (isChecked) {
          this.selected = this.users;
        } else {
          this.selected = [];
        }
      }
    },
    onImport(event) {
      const files = event.target.files;
      if (files.length > 0) {
        const file = files[0];
        readXlsxFile(file, { schema }).then(({ rows, errors }) => {
          if (errors.length === 0) {
            const users = rows;
            this.createBulk(users);
          }
        });
      }
    },
    closeDialogImport() {
      this.showFileImport = false;
    },
    handleImportBulk(excelDataConverted) {
      const excelData = duplicateVar(excelDataConverted.body);
      this.tmpUsers = excelData;
    },
    createBulk() {
      this.$vs.loading({ type: 'point' });
      this.isImporting = true;
      const notifTitle = this.$t('Import Users');
      const callback = (response) => {
        const data = response.data;
        const message = response.message;
        const newUsers = data.createdUsers;
        const existingUsers = data.recoveryUsers;

        newUsers.forEach((item) => {
          this.insert(item);
        });

        existingUsers.forEach((item) => {
          this.insert(item);
        });

        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.$vs.loading.close();
        this.isImporting = false;
        this.tmpUsers = [];
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isImporting = false;
      };
      usersApi.bulkCreate((this.tmpUsers), callback, errorCallback);
    },
    AddNewBroadcast() {
      this.dialogDataBroadcast = {};
      this.toggleDataDialogBroadcast(true);
    },
    toggleDataDialogBroadcast(val = false) {
      this.AddNewDataDialogBroadcast = val;
    },
    exportUsers(type) {
      this.isExporting = true;
      const params = {
        page: this.page,
        limit: this.limit,
        order_by: this.orderBy,
        sort_by: this.sortBy,
      };
      const callback = (response) => {
        const contents = response;
        downloadBlob(contents, { name: `${this.$t('Users')}`, type });
        this.isExporting = false;
      };
      const errorCallback = () => {
        this.isExporting = false;
      };
      usersApi.exportList(type, params, callback, errorCallback);
    },
    // change password
    toggleDataSidebarPassword(val = false) {
      this.addNewDataSidebarPassword = val;
    },
    changePassword(user) {
      this.sidebarDataPassword = duplicateVar(user);
      this.toggleDataSidebarPassword(true);
    },
    filterSuperAdmin() {
      this.role = 'super_admin';
      this.isFilterAll = false;
      this.isFilterSuperAdmin = true;
      this.isFilterClient = false;
      this.isFilterParticipant = false;
    },
    filterClient() {
      this.role = 'client';
      this.isFilterAll = false;
      this.isFilterSuperAdmin = false;
      this.isFilterClient = true;
      this.isFilterParticipant = false;
    },
    filterParticipant() {
      this.role = 'participant';
      this.isFilterAll = false;
      this.isFilterSuperAdmin = false;
      this.isFilterClient = false;
      this.isFilterParticipant = true;
    },
    filterAll() {
      this.role = '';
      this.isFilterAll = true;
      this.isFilterSuperAdmin = false;
      this.isFilterClient = false;
      this.isFilterParticipant = false;
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
    }),
  },
  created() {
    this.fetch();
    this.filterAll();
    document.addEventListener('click', this.onSelectAll);
  },
  destroyed() {
    document.removeEventListener('click', this.onSelectAll);
  },
  mounted() {
    this.isMounted = true;
  },
  watch: {
    page() {
      this.fetch();
    },
    role() {
      this.fetch(true);
    },
  },
};
</script>

<style lang="scss">
.icon-filter{
	position: absolute;
	right: 0;
	padding-right: 12px;
}
.icon-filter:before {
    content: "\e875";
    color: white;
}
</style>
