<template>
	<!-- Change Password -->
		<vs-prompt
			class="dialog-password"
			:title="printTitlePrompt()"
			:is-valid="isFormValid"
			@accept="changePassword()"
			@cancel="closeSidebar()"
			@close="closeSidebar()"
			:accept-text="$t('Save Changes')"
			:cancel-text="$t('Cancel')"
			:active.sync="isSidebarActiveLocal">
			<div class="con-exemple-prompt broadcast-container">
				<div class="p-6">
					<form>
						<div class="vx-row">
							<div class="vx-col w-full mb-2" v-if="activeRouteName !== 'users'">
								<!-- Old Passwrod -->
								<vs-input v-filter-input
									autocomplete
									:label-placeholder="$t('Current Password')"
									v-model="old_password"
									class="w-full"
									name="Old Password"
									v-validate="'required'"
									type="password"
								/>
							</div>
							<div class="vx-col w-full mb-2">
								<!-- New Password -->
								<vs-input v-filter-input
									autocomplete
									:label-placeholder="$t('New Password')"
									v-model="new_password"
									class="w-full"
									:class="{'mt-8': activeRouteName !== 'users', 'mt-0': activeRouteName === 'users'}"
									name="New Password"
									v-validate="'min:6'"
									type="password"
								/>
								<span
									class="text-danger text-sm"
									v-show="errors.has('New Password')"
								>{{ errors.first('New Password') }}</span>
							</div>
						</div>
						<div class="vx-row">
							<div class="vx-col w-full mb-2">
								<!-- Confirm Password -->
								<vs-input v-filter-input
									autocomplete
									:label-placeholder="$t('Confirm Password')"
									v-model="confirm_password"
									class="mt-8 w-full"
									name="Confirm Password"
									v-validate="'min:6'"
									type="password"
								/>
								<span
									class="text-danger text-sm"
									v-show="errors.has('Confirm Password')"
								>{{ errors.first('Confirm Password') }}</span>
							</div>
						</div>
					</form>
				</div>
			</div>
			<!-- action button sidebar change password-->
			<div class="flex flex-wrap items-center p-6" slot="footer">
				<vs-button
					class="mr-6"
					:class="{ 'is-loading': isSaving }"
					:disabled="!isFormValid"
					@click="changePassword">
					{{ $t('Submit') }}
				</vs-button>
				<vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{ $t('Cancel') }}</vs-button>
			</div>
		</vs-prompt>
</template>

<script>
/* eslint-disable camelcase */
import usersApi from '@/api/user';
import authApi from '../api/auth';
import { getAxiosErrorMessage } from '../lib/helper';

export default {
  name: 'PasswordSidebar',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    eventId: {
      type: String,
    },
  },
  components: {
  },
  data() {
    return {
      old_password: '',
      new_password: '',
      confirm_password: '',
      password: '',
      showPassword: false,
      showPassword2: false,
      valid: true,
      isSaving: false,
      isUploading: false,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      icon: null,
      paramsUser: null,
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.$validator.reset();
      } else {
        this.$validator.reset();
      }
    },
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar');
          this.$validator.reset();
        }
      },
    },
    isFormValid() {
      return (
        !this.errors.any()
				// && this.old_password !== ''
				&& this.new_password !== ''
				// && this.confirm_password !== ''
				&& this.new_password === this.confirm_password
      );
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    params() {
      const params = {
        old_password: this.old_password,
        new_password: this.new_password,
        confirm_password: this.confirm_password,
      };
      return params;
    },
    activeRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    printTitlePrompt() {
      if (this.activeRouteName === 'users') {
        return `${this.$t('Change Password')} (${this.data && this.data.profile ? this.data.profile.name : ''})`;
      }
      return `${this.$t('Change Password')}`;
    },
    resetInput() {
      this.old_password = '';
      this.new_password = '';
      this.confirm_password = '';
    },
    changePassword() {
      this.isSaving = true;
      if (this.activeRouteName === 'users') {
        const password = this.confirm_password;
        const param = {
          // eslint-disable-next-line
					password: password,
        };
        this.paramsUser = param;
      }
      const callback = (response) => {
        const message = response.message;
        this.$emit('closeSidebar');
        this.$vs.notify({
          title: this.$t('User'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isSaving = false;
        this.resetInput();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('User'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSaving = false;
        this.resetInput();
      };
      if (this.activeRouteName === 'users') {
        usersApi.forceUpdatePassword(this.data.id, this.paramsUser, callback, errorCallback);
      } else {
        authApi.changePassword(this.params, callback, errorCallback);
      }
    },
    changePasswordUser() {
      this.isSaving = true;
      const password = this.confirm_password;
      const params = password;
      const callback = (response) => {
        const message = response.message;
        this.$emit('closeSidebar');
        this.$vs.notify({
          title: this.$t('User'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isSaving = false;
        this.resetInput();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('User'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSaving = false;
        this.resetInput();
      };
      usersApi.update(this.data.id, params, callback, errorCallback);
    },
    closeSidebar() {
      this.$emit('closeSidebar');
    },
  },
};
</script>
<style>
	[dir] .dialog-edit-password.con-vs-dialog .vs-dialog .vs-dialog-text {
    padding: 1rem;
	min-height: 600px;
	max-height: 600px;
}
[dir] .dialog-edit-password.con-vs-dialog .vs-dialog {
	max-width: 484px;
    min-width: 484px;
    width: 70%;
}
</style>
